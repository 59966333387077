body { background-color: #ffffff; }
body { color: var(--clr-18717); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-18721);
font-family: 'Montserrat';
font-weight: 600;
font-style: normal;
line-height: 1.1;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: var(--clr-18721);
font-family: 'Montserrat';
font-weight: 600;
font-style: normal;
line-height: 1.1;
font-size: 24px;

@media #{$medium-up} {
font-size: 38px;

}
}
h3 {
color: var(--clr-18721);
font-family: 'Montserrat';
font-weight: 300;
font-style: normal;
line-height: 1.5;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: var(--clr-18721);
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: var(--clr-18721);
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: var(--clr-18721);
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 16px;

}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Arapey';
font-weight: normal;
font-style: normal;
line-height: 1.2;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
@media #{$large-up} {
font-size: 22px;

}
}
.me-Quote .quote-author {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: var(--clr-18717);
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: 15;
font-family: 'Roboto';
font-weight: 300;
font-style: normal;
line-height: 1.1;
font-size: 20px;

}
summary {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Roboto';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 16px;

}
nav.mainmenu > .menu-item > div > a {
font-family: 'Roboto';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 16px;

}
nav.secondarymenu > .menu-item > a {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
nav.me-RespMenu.responsive-menu a {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
body {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-18721);}
a:hover {color: var(--clr-18719);}
/* Secondary:2 */
.MES2 {
background-color: var(--clr-18719);
color: var(--clr-18717);
 }
/* Secondary:3 */
.MES3 {
background-color: var(--clr-18719);
color: var(--clr-18717);
 }
.MES3 {
background-color: var(--clr-18719);
color: var(--clr-18717);
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: var(--clr-18717);
 }
 }
cite.MEC3{
color: var(--clr-18717);
}
/* Primary:4 */
.MES4 {
background-color: var(--clr-18721);
&:hover {background-color: var(--clr-18732);}
color: #ffffff;
&:hover { color: #ffffff;}
 }
/* Primary:5 */
.MES5 {
background-color: var(--clr-18721);
color: #ffffff;
 }
.MES5 {
background-color: var(--clr-18721);
color: #ffffff;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: #ffffff;
 }
 }
cite.MEC5{
color: #ffffff;
}
/* footer line:6 */
.MES6 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-18720) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Main Menu:7 */
nav.me-Menu.MES7 {
& .menu-item.MEC7, & .menu-item.MEC7 > div.MEC7{ & > a.MEC7{color: var(--clr-18732);
text-transform: uppercase;
}
 &:hover > a.MEC7{color: var(--clr-18721);
}
 }
&.horizontal > .menu-item.MEC7 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC7 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC7 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 2;
border-style: dotted;
 }
&.horizontal > .menu-item.MEC7 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 24px 0 24px 10px;}

& .sub-menu .menu-item a{padding: 15px;}


& > .menu-item.MEC7.active { & > a{ color: var(--clr-18719);}
 }
&.horizontal .menu-item.MEC7:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-18721-flat);}}
&.vertical .menu-item.MEC7:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-18721-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC7{background-color: var(--clr-18721); &:hover {background-color: #ffffff;}
}
& .menu-item.MEC7, & .menu-item.MEC7 > div.MEC7{ & > a.MEC7{color: #ffffff;
}
 &:hover > a.MEC7{color: var(--clr-18732);
}
 }

}
}
 }
/* Footer Panel:8 */
.MES8 {
background-color: var(--clr-18720);
color: #ffffff;
font-size: 12.8px;
padding: 30px 15px 15px 15px;

 }
.MES8 {
background-color: var(--clr-18720);
color: #ffffff;
font-size: 12.8px;
padding: 30px 15px 15px 15px;

h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: #ffffff;
 }
 }
cite.MEC8{
color: #ffffff;
font-size: 12.8px;
}
/* Header Icon Panel:9 */
.MES9 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-18722);}
color: var(--clr-18717);
 }
.MES9 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-18722);}
color: var(--clr-18717);
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: var(--clr-18717);
 }
 }
cite.MEC9{
color: var(--clr-18717);
}
/* Slider Mask:10 */
.MES10 {
background-color: var(--clr-18723);
color: #ffffff;
padding: 0;

 }
.MES10 {
background-color: var(--clr-18723);
color: #ffffff;
padding: 0;

h1.MEC10, h2.MEC10, h3.MEC10, h4.MEC10, h5.MEC10, h6.MEC10 { color: #ffffff;
 }
 }
cite.MEC10{
color: #ffffff;
}
/* Footer Menu:11 */
nav.me-Menu.MES11 {
& .menu-item.MEC11, & .menu-item.MEC11 > div.MEC11{ & > a.MEC11{color: #ffffff;
}
 &:hover > a.MEC11{color: var(--clr-18718);
}
 }
&.horizontal > .menu-item.MEC11 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC11 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC11 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC11 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 0;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Standard Button:12 */
.MES12 {
background-color: var(--clr-18724);
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 12px;
@media #{$large-up} {
font-size: 14.4px;
};
padding: 10px;

@media #{$large-up} {
padding: 10px 20px;

}
 }
/* Banner Strip:14 */
.MES14 {
background-color: var(--clr-18725);
min-height:100px;
@media #{$medium-up} {
min-height: 110px;};
@media #{$large-up} {
min-height: 80px;};
padding: 15px;

 }
.MES14 {
background-color: var(--clr-18725);
min-height:100px;
@media #{$medium-up} {
min-height: 110px;};
@media #{$large-up} {
min-height: 80px;};
padding: 15px;

 }
/* Slider Panel:15 */
.MES15 {
background-color: #000000;
 }
.MES15 {
background-color: #000000;
 }
/* Top Strip Panel:16 */
.MES16 {
background-color: #ffffff;
color: var(--clr-18717);
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-18726) transparent;
 }
.MES16 {
background-color: #ffffff;
color: var(--clr-18717);
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-18726) transparent;
h1.MEC16, h2.MEC16, h3.MEC16, h4.MEC16, h5.MEC16, h6.MEC16 { color: var(--clr-18718);
 }
h1.MEC16 { @media #{$medium-up} { font-size: 32px; }; }
h1.MEC16 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC16 { @media #{$medium-up} { font-size: 30.4px; }; }
h2.MEC16 { @media #{$large-up} { font-size: 38px; }; }
h3.MEC16 { @media #{$medium-up} { font-size: 24px; }; }
h3.MEC16 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC16 { @media #{$medium-up} { font-size: 20px; }; }
h4.MEC16 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC16 { @media #{$medium-up} { font-size: 16px; }; }
h5.MEC16 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC16 { @media #{$medium-up} { font-size: 14.4px; }; }
h6.MEC16 { @media #{$large-up} { font-size: 18px; }; }
 }
a.MEC16 { color: var(--clr-18717);
&:hover { color: var(--clr-18718);}
 }
cite.MEC16{
color: var(--clr-18717);
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
}
/* Shade 1:17 */
.MES17 {
background-color: var(--clr-18727);
color: var(--clr-18717);
 }
/* Shade 1:18 */
.MES18 {
background-color: var(--clr-18727);
color: var(--clr-18717);
 }
.MES18 {
background-color: var(--clr-18727);
color: var(--clr-18717);
h1.MEC18, h2.MEC18, h3.MEC18, h4.MEC18, h5.MEC18, h6.MEC18 { color: var(--clr-18717);
 }
 }
cite.MEC18{
color: var(--clr-18717);
}
/* Shade 2:19 */
.MES19 {
background-color: var(--clr-18726);
color: var(--clr-18717);
 }
/* Shade 2:20 */
.MES20 {
background-color: var(--clr-18726);
color: var(--clr-18717);
 }
.MES20 {
background-color: var(--clr-18726);
color: var(--clr-18717);
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: var(--clr-18717);
 }
 }
cite.MEC20{
color: var(--clr-18717);
}
/* Alternate:21 */
.MES21 {
background-color: var(--clr-18728);
color: #ffffff;
 }
/* Alternate:22 */
.MES22 {
background-color: var(--clr-18728);
color: #ffffff;
 }
.MES22 {
background-color: var(--clr-18728);
color: #ffffff;
h1.MEC22, h2.MEC22, h3.MEC22, h4.MEC22, h5.MEC22, h6.MEC22 { color: #ffffff;
 }
 }
cite.MEC22{
color: #ffffff;
}
/* Dark:23 */
.MES23 {
background-color: var(--clr-18717);
color: #ffffff;
 }
/* Dark:24 */
.MES24 {
background-color: var(--clr-18732);
color: #ffffff;
 }
.MES24 {
background-color: var(--clr-18732);
color: #ffffff;
h1.MEC24, h2.MEC24, h3.MEC24, h4.MEC24, h5.MEC24, h6.MEC24 { color: #ffffff;
 }
 }
cite.MEC24{
color: #ffffff;
}
/* Video Frame:25 */
.MES25 {
background-color: var(--clr-18727);
padding: 0 10px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-18719);
 }
.MES25 {
background-color: var(--clr-18727);
padding: 0 10px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-18719);
 }
/* Arrow slider:26 */
.MES26 {
& .slider-arrow {color: var(--clr-18729);
font-size: 100px;
@media #{$medium-up} {
font-size: 100px;
};
@media #{$large-up} {
font-size: 100px;
};
}& .slider-arrow:hover{color: var(--clr-18723);
} }
/* Responsive Menu:28 */
nav.responsive-menu {
.menu-item.MEC28{background-color: var(--clr-18732);}
& .menu-item.MEC28, & .menu-item.MEC28 > div.menu-item-wrap{ & > a.MEC28, & > i{color: #ffffff;
text-transform: uppercase;
text-align: left;
}
  }
& .menu-item.MEC28 { border:0;
border-color: var(--clr-18727);border-style: dotted;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 10px;}

& .sub-menu{.menu-item.MEC28{background-color: 17;}
 .sub-menu {.menu-item.MEC28{background-color: 3;}
}}

 }
/* Standard Button 2:27 */
.MES27 {
background-color: var(--clr-18718);
&:hover {background-color: var(--clr-18728);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 5px;
padding: 10px 30px;

border-width: 1px;
border-style: solid;
 }
/* Footer Menu:29 */
nav.me-Menu.MES29 {
& .menu-item.MEC29, & .menu-item.MEC29 > div.MEC29{ & > a.MEC29{color: #ffffff;
text-transform: uppercase;
}
 &:hover > a.MEC29{color: var(--clr-18721);
}
 }
&.horizontal > .menu-item.MEC29 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC29 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC29 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 2;
border-style: dotted;
 }
&.horizontal > .menu-item.MEC29 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 24px 20px;}

& .sub-menu .menu-item a{padding: 10px;}


& > .menu-item.MEC29.active { & > a{ color: var(--clr-18729);}
 }
&.horizontal .menu-item.MEC29:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-18718-flat);}}
&.vertical .menu-item.MEC29:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-18718-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC29{background-color: var(--clr-18718); &:hover {background-color: 27;}
}
& .menu-item.MEC29, & .menu-item.MEC29 > div.MEC29{ & > a.MEC29{color: #ffffff;
}
 &:hover > a.MEC29{color: 2;
}
 }

}
}
 }
/* Box Panel:30 */
.MES30 {
padding: 10px;

 }
.MES30 {
padding: 10px;

 }
/* Standard Button:31 */
.MES31 {
color: var(--clr-18720);
&:hover { color: var(--clr-18720);}
padding: 10px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-18720);
&:hover { border-color: var(--clr-18720); }
 }
/* Carousel:32 */
.MES32 {
& .slider-arrow {color: var(--clr-18728);
font-size: 50px;
@media #{$medium-up} {
font-size: 50px;
};
@media #{$large-up} {
font-size: 50px;
};
}& .slider-arrow:hover{color: var(--clr-18722);
} }
/* :33 */
details.MES33 {
& > summary{background-color: var(--clr-18726);
}
& > summary{padding: 10px;}

& > summary{border-width: 1px;
border-style:solid;
border-color: var(--clr-18719);
} }
/* Folio List Panel:34 */
.MES34 {
padding: 10px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-18726);
 }
.MES34 {
padding: 10px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-18726);
 }
/* Carousel Panel:35 */
.MES35 {
padding: 10px 15px;

 }
.MES35 {
padding: 10px 15px;

 }
/* File Button:36 */
.MES36 {
background-color: #000000;
&:hover {background-color: #ffffff;}
color: #ffffff;
&:hover { color: #000000;}
padding: 5px 15px;

border-width: 1px;
border-style: solid;
border-color: #000000;
&:hover { border-color: #ffffff; }
 }
/* File Mask:37 */
.MES37 {
&:hover, &.hover { background-color: var(--clr-18730);}
color: #ffffff;
 }
.MES37 {
&:hover, &.hover { background-color: var(--clr-18730);}
color: #ffffff;
h1.MEC37, h2.MEC37, h3.MEC37, h4.MEC37, h5.MEC37, h6.MEC37 { color: #ffffff;
 }
 }
cite.MEC37{
color: #ffffff;
}
/* File Block Panel:38 */
.MES38 {
padding: 0;

border-width: 1px;
border-style: solid;
border-color: var(--clr-18720);
 }
.MES38 {
padding: 0;

border-width: 1px;
border-style: solid;
border-color: var(--clr-18720);
 }
/* Standard Accorian:39 */
details.MES39 {
color: #000000;
& > article { color: #000000;
 }
& > summary { color: var(--clr-18721);
 }
 }
/* Email Content Panel:40 */
.MES40 {
background-color: #ffffff;
padding: 15px;

 }
.MES40 {
background-color: #ffffff;
padding: 15px;

 }
/* :41 */
.MES41 {
color: #ffffff;
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
 }
.MES41 {
color: #ffffff;
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
 }
cite.MEC41{
color: #ffffff;
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
}
/* Tester For Niall:42 */
.MES42 {
color: #ffffff;
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
 }
.MES42 {
color: #ffffff;
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
 }
cite.MEC42{
color: #ffffff;
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
}
/* Accordion:56 */
details.MES56 {
color: var(--clr-18721);
& > article { color: var(--clr-18721);
 }
& > summary { color: var(--clr-18721);
 }
 }
